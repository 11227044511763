import PropTypes from 'prop-types'
import Link from 'next/link'
import classNames from 'classnames'
import { get } from 'lodash'

import Icon from '../../bantrab/icon'
import Typography from '../../bantrab/typography'
import StickyForm from './sticky-form'

import { useBreakpoint } from '../../../hooks/breakpoint'
import { useAuth } from '../../../hooks/auth'


import Partner from '../../../public/img/bantrab/partner-color-footer.svg'

import Bantrab from '../icon/bantrab.svg'
import Googleplay from '../icon/googleplay.svg'
import Appstore from '../icon/appstore.svg'
import Crehana from '../icon/crehana.svg'
import Facebook from '../icon/facebook-f.svg'
import LinkedIn from '../icon/linkedin-in.svg'
import Twitter from '../icon/twitter-outline.svg'
import Youtube from '../icon/youtube.svg'

import styles from '../../../styles/bantrab/components/footer.module.scss'

const SOCIALMEDIA_ICONS = {
  facebook: Facebook,
  linkedin: LinkedIn,
  twitter: Twitter,
  youtube: Youtube
}

const PAGE_INDEX_TYPE_PATH_MAP = {
  'transmissions.TransmissionsIndexPage': '/webinars',
  'courses.CoursesIndexPage': '/cursos',
  'resources.ResourcesIndexPage': '/recursos',
  'news.NewsIndexPage': '/blog',
  'specializations.SpecializationsIndexPage': '/especializaciones'
}

const SocialNetworks = ({ socialmedia, ...attrs }) => {
  attrs.className = classNames(
    attrs.className,
    [
      'flex',
      'items-center'
    ]
  )
  return <ul {...attrs}>
    {
      Array.from(Object.keys(socialmedia)).map(
        (label, index) => {
          const url = socialmedia[label]
          const icon = get(SOCIALMEDIA_ICONS, label, null)

          return icon
            ? <li
              key={index}
              className={'mr-4 bg-gray-400 text-white w-8 h-8 flex items-center justify-center rounded-full'}>
              <a href={url}>
                <Icon
                  svg={icon}
                  height={16}
                  width={16} />
              </a>
            </li>
            : null
        }
      )
    }
  </ul>
}

SocialNetworks.propTypes = {
  socialmedia: PropTypes.object
}

const Footer = ({ menu, legal, socialmedia, name, mobileapplications, ...attrs }) => {
  const breakpoint = useBreakpoint()
  const auth = useAuth()

  for (const label in legal) {
    if (!legal[label]) {
      delete legal[label]
    }
  }

  for (const label in socialmedia) {
    if (!socialmedia[label]) {
      delete socialmedia[label]
    }
  }

  attrs.className = classNames(
    attrs.className,
    [
      'relative'
    ],
    {
      [styles.footer]: true
    }
  )

  return (
    <footer {...attrs}>
      <div
        className={
          classNames([
            'relative',
            'container',
            'px-4'
          ])
        }>
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-gray-400 text-center md:text-left">
          <div
            className="flex flex-col justify-between">
            <div
              className="mb-4">
              <Partner
                className={breakpoint.mdAndUp ? null : 'block mx-auto'}
                height={breakpoint.mdAndUp ? 48 : null}
                style={{
                  width: breakpoint.mdAndUp ? null : '100%',
                  maxWidth: breakpoint.mdAndUp ? null : '18.5rem',
                  height: 'auto'
              }} />
              <Typography
                size={'three'}
                weight={'black'}
                className="uppercase">
                Tu bienestar
                <Typography
                  size={'two'}
                  weight={'regular'}
                  className="uppercase">
                  es nuestro trabajo.
                </Typography>
              </Typography>
            </div>

            <Typography
              size={'three'}>
              <strong>Estudia gratis</strong>{' '}
              y conviértete en un experto.
            </Typography>
          </div>

          <div>
            <ul>
              {
                (menu && menu.length > 0)
                  ? <>
                    <li className="mb-2">
                      <Typography
                        size="six"
                        weight="bold"
                        className="text-gray-500 mb-4">
                        Enlaces
                      </Typography>
                    </li>
                    {
                      menu.map((item, index) => {
                        const url = get(PAGE_INDEX_TYPE_PATH_MAP, item.type, null)
                        return url
                          ? <li
                            key={index}
                            className="mb-2">
                            <Link
                              href={url}>
                              <a>
                                {item.name}
                              </a>
                            </Link>
                          </li>
                          : null
                      })
                    }
                  </>
                  : ''
              }
            </ul>
          </div>

          <div>
            <Typography
              size="six"
              weight="bold"
              className="text-gray-500 mb-4">
              Conecta
            </Typography>

            <SocialNetworks
              socialmedia={socialmedia}
              className={'text-gray-600 justify-center md:justify-start'} />
          </div>
        </div>
        

        <div
          className={
            classNames([
              'border-t',
              'border-gray-400',
              'mt-4',
              'py-4',
              'px-8',
              'text-center',
              'lg:text-left',
              'flex',
              'flex-col',
              'lg:flex-row',
              'lg:justify-between',
              'items-center',
              'text-gray-400'
            ])
          }>
          <Typography
            size="three"
            weight="medium">
              &copy; 2023 Bantrab. Derechos reservados.
          </Typography>

          <div
            className="flex flex-col md:flex-row items-center justify-around w-full lg:w-1/2">
            {
              legal
                ? Array.from(Object.keys(legal)).map(
                  pageLabel => {
                    const page = legal[pageLabel]

                    return <Link
                      key={page.id}
                      href={`/legal/${page.id}/${page.meta.slug}`}>
                      <a
                        className="mt-2 md:mt-4 lg:mt-0">
                        {page.title}
                      </a>
                    </Link>
                  }
                )
                : null
            }
          </div>
        </div>
      </div>

      {
        (auth && auth.isAuthenticated && auth.user)
          ? null
          : <StickyForm />
      }
    </footer>
  )
}

Footer.propTypes = {
  menu: PropTypes.array,
  legal: PropTypes.object,
  socialmedia: PropTypes.object,
  mobileapplications: PropTypes.object,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default Footer
